<template>
  <div class="wxb-userCard">
    <div class="top" @click="toDetail">
      <div class="head-img">
        <img :src="info.avatar" alt=""
             fit="cover" loading-icon="van-image__loading-icon" :onerror="defaultImg" />
      </div>
      <div class="user-info">
        <div class="name">
          <span class="userName">{{info.nickName}}</span>
          <slot name="todos">
            <div class="todos">
              <template v-if="info.clueEstateStage!== null">
                <wxbStatusTag class="status" :color="info.clueEstateStage | colorFilter">
                  {{ info.clueEstateStage | filterCusStatus }}
                </wxbStatusTag>
              </template>
              <svg v-if="info.hasFocusQw == 1" class="icon top-icon" aria-hidden="true" style="width: 16px; height: 16px">
                <use xlink:href="#iconqiyeweixin"></use>
              </svg>
            </div>
          </slot>
        </div>
        <slot name="tel">
          <div class="tel" v-if="info.phone">{{info.phone}}</div>
        </slot>
      </div>
    </div>
    <slot name="bottom">
      <div class="bottom">
        <slot name="bottom-left">
          <div class="bottom-left" @click="otherPerson">
            <span>可能的好友 {{info.friendsNum}} </span>
            <img src="../../assets/images/allow-right-icon.png" class="icon-other" alt="">
          </div>
        </slot>
        <slot name="bottom-right">
          <div class="bottom-right">
            <template>
              <!--  企业微信  -->
              <svg v-if="info.hasFocusQw == 1 && openSession" @click="openChat" class="icon icon-max" aria-hidden="true" style="margin-right: 12px">
                <use xlink:href="#iconliaotian"></use>
              </svg>
              <!--  打电话  -->
              <svg @click="callPhone" v-if="info.phone" class="icon icon-max" aria-hidden="true" style="margin-right: 12px">
                <use xlink:href="#iconbodadianhua"></use>
              </svg>
            </template>
          </div>
        </slot>
      </div>
    </slot>
  </div>
</template>

<script>
  import dayjs from 'dayjs';
  import clue from '../../api/clue';

  const ClueGroupEnum = {
    Incubation: 0, //孵化中
    Before: 1, //待转化
    Invalid: 2 //无效
  };
export default {
  name: 'wxbUserCard',
  props: {
    userInfo: {
      type: Object,
      default: () => null,
    },
    openSession: {
      type: Boolean,
      default: true,
    }, //是否显示打开微信对话按钮
    jumpCard: {
      type: Boolean,
      default: () => true,
    }, //点击卡片是否跳转
  },
  watch: {
    userInfo: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) return;
        this.info = val;
      },
    },
  },
  filters: {
    filterDays (val) {
      if (val == '1970-01-01 00:00:00') {
        return '--';
      }
      return dayjs(val).format('YYYY-MM-DD HH:mm');
    },
    filterCusStatus(val) {
      let str = '';
      switch (val) {
        case 0:
          str = '孵化中';
          break;
        case 1:
          str = '待转化';
          break;
        case 2:
          str = '已转化';
          break;
        case 3:
          str = '无效';
          break;
        default:
          str = '';
          break;
      }
      return str;
    },
    colorFilter(val) {
      let str = '';
      switch (val) {
        case 0:
          str = '#FFA827';
          break;
        case 1:
          str = '#6AD038';
          break;
        case 2:
          str = '#3A74F2';
          break;
        case 3:
          str = '#FA5D5D';
          break;
        default:
          str = '#6AD038';
          break;
      }
      return str;
    },
  },
  data() {
    return {
      ClueGroupEnum,
      info: {
        // clueId: '111',
        // clueEstateId: '2222',
        // nickName: '张三',
        // avatar: '',
        // phone: '18612341234',
        // clueEstateShowStatus: 0, // 0孵化中 1待转化 2无效
        // clueTags: [], //标签
        // hasValid: 0, //是否有效:0否 1是
        // latestVisitTime: '', //最近访问时间
        // hasFocusQw: 1, //是否是企微员工好友
        // unionId: '1234', //微信开放平台用户ID
        // convertCountdownDays: 99, //转化倒计时天数
        // invalidReason: '', //无效原因
        // invalidTime: '111', //无效时间
        // backTime: '', //入库时间
        // backReason: '' //入库原因
      },
      showPopover: false,
    };
  },
  computed: {
    defaultImg () {
      return 'this.src="' + require('@/assets/images/default_head_icon.png') + '"';
    },
  },
  methods: {
    //拨打手机
    callPhone() {
      window.location.href = `tel:${this.info.phone}`;
    },
    //打开企业微信
    async openChat() {
      let params = {clueId: this.info.clueId, opId: localStorage.getItem('platform_opId')};
      let {code, data, msg} = await clue.getOtherClue(params);
      if (code !== '0' || !data) {
        console.error('接口报错', msg);
        return;
      }
      this.$wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: '', //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: data.externalUserId, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: function (res) {
          console.log(res, '======>>>>>群聊id');
          // 回调
        },
        fail: function (res) {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        },
      });
    },
    toDetail() {
      if (!this.jumpCard) return;
      let jumpHost = `${window.location.protocol}//` + location.host;
      window.location.href = jumpHost + `/potential/#/clue-detail?clueId=${this.info.clueId}`;
    },
    otherPerson() {
      this.$emit('otherPerson', {clueId: this.info.clueId});
    }
  }
};
</script>

<style lang="less" scoped>
  .icon{
    width: 32px;
    height: 32px;
  }
  .icon-max{
    width: 32px !important;
    height: 32px !important;
  }
  .wxb-userCard{
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    .top{
      display: flex;
      .head-img{
        width: 48px;
        height: 48px;
        border-radius: 8px;
        overflow: hidden;
        img{
          width: 48px;
          height: 48px;
        }
      }
      .user-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 12px;
        .name{
          margin-top: 4px;
          display: flex;
          align-items: center;
          .userName{
            font-size: 15px;
            font-weight: bold;
            color: @gray3;
            margin-right: 8px;
            line-height: 21px;
          }
          .todos{
            display: flex;
            align-items: center;
            .status{
              margin-right: 8px;
            }
          }

        }
        .tel{
          font-size: 12px;
          font-weight: 400;
          color: @gray6;
          margin-top: 4px;
          line-height: 17px;
          -webkit-user-select:text;
          -moz-user-select:text;
          -ms-user-select:text;
          user-select:text;
        }
      }
    }
    .bottom{
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bottom-left{
        height: 24px;
        line-height: 24px;
        background: rgba(24, 208, 204, 0.1);
        border-radius: 4px;
        padding: 0 8px;
        span{
          font-size: 12px;
          font-weight: 400;
          color: #18D0CC;
          line-height: 17px;
        }
        .icon-other{
          width: 5px;
          height: 12px;
          color: #18D0CC;
          margin-left: 4px;
          vertical-align: middle;
        }
      }
      .bottom-right{
        display: flex;
        align-items: center;
        .operate {
          .get-user {
            width: 64px;
            height: 32px;
            background: #F0F4FE;
            color: #3a74f2;
            text-align: center;
            line-height: 32px;
            border-radius: 4px;
            font-size: 12px;
          }
          .get-user:active {
            opacity: 0.6;
          }
        }
      }
    }
  }
</style>
