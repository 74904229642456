/*
 * @Author: your name
 * @Date: 2020-12-03 10:27:37
 * @LastEditTime: 2022-05-11 14:07:01
 * @LastEditors: yuBiao
 * @Description: In User Settings Edit
 * @FilePath: \online-sale-h5\src\util\util.js
 */
import login from '@/api/login';

const ua = navigator.userAgent;
// Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Mobile/14F89
const isPad = () => ua.indexOf('iPad') > -1;
const isIpod = () => ua.indexOf('iPod') > -1;
const iphone = () => ua.indexOf('iPhone') > -1;

export const isAndroid = () => ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1;
export const isIos = () => isPad() || isIpod() || iphone();

export const getRoleFlag = () => {
  const role = JSON.parse(localStorage.getItem('crmRole')) || {};
  return role.saleRoleName && role.saleRoleName === '置业顾问' ? 0 : 1;
};

export const getQueryString=(val)=>{

  const w = location.hash.indexOf('?');
  const query = location.hash.substring(w + 1);

  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == val) { return pair[1]; }
  }

  return (false);

};
/**
 * @Description jssdk agentConfig注册方法，ps:微信某些api调用必须要先使用agentConfig注册
 * @param cb-注册成功需要执行的方法
 * @param roleNames-需要注册的企微invoke方法名
 * @returns {Promise<void>}
 */
export const wxAgentConfig=async (cb, that, roleNames)=>{
  let suiteId = localStorage.getItem('platform_agentId');
  let corpId = localStorage.getItem('platform_corpId');
  let opId = localStorage.getItem('platform_opId');
  const targetUrl = location.href.split('#')[0];
  let params = { url: targetUrl, suiteId, corpId, opId };
  let res = await login.getEnter(params, true);
  that.$wx.agentConfig({
    corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
    agentid: suiteId, // 必填，企业微信的应用id （e.g. 1000247）
    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.noncestr, // 必填，生成签名的随机串
    signature: res.data.agentConfigSignature, // 必填，签名，见附录-JS-SDK使用权限签名算法
    jsApiList: roleNames, //必填，传入需要使用的接口名称
    success: (res) => {
      console.log('agentConfig 注册成功', res, roleNames);
      //侧边栏进入判断入口
      try {
        cb();
      } catch (e) {
        console.log(e, '兼容pc无invoke方法');
      }
      // 回调
    },
    fail: (res) => {
      if (res.errMsg === 'agentConfig:fail') {
        console.log(res, '兼容pc无agentConfig方法');
      }
      if (res.errMsg.indexOf('function not exist') > -1) {
        alert('版本过低请升级');
      }
      console.log(`agentConfig 注册失败===>>>${res.errMsg}`);
    },
  });
};

// 遍历树节点返回符合的节点集合
export const recursionGet = (ids, data, result, childrenKey = 'children', filterKey = 'id') => {
  if (!ids || ids.length <= 0) {
    return;
  }
  for (let i = 0; i < data.length; i++) {
    if (ids.indexOf(data[i][filterKey]) >= 0) {
      result.push(data[i]);
    }
    if (data[i][childrenKey] && data[i][childrenKey].length > 0) {
      recursionGet(ids, data[i][childrenKey], result, childrenKey, filterKey);
    }
  }
};
