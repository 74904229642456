export default [
    //话术百宝箱
    {
      path: '/trickchest',
      meta: {
        title: '话术百宝箱',
      },
      component: () => import('@/views/sidebar/trickchest'),
    },
    {
      path: '/talkCode',
      meta: {
        title: '拓客码',
      },
      component: () => import('@/views/sidebar/talkCode'),
    }
  ];
