export default [
  //关键用户
  {
    path: '/keyUser',
    meta: {
      title: '关键用户',
    },
    component: () => import('@/views/clue/keyUser'),
  },
];
