<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: yuBiao
 * @Date: 2022-01-21 14:39:39
 * @LastEditors: yuBiao
 * @LastEditTime: 2022-04-18 16:22:32
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import login from '@/api/login';
export default {
  name: 'App',
  methods: {
    //获取url参数
    getUrlparam(param) {
      const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
      const r = window.location.search.substr(1).match(reg) || window.location.hash.substring(window.location.hash.search(/\?/) + 1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
    },
    //消息卡片跳转刷新项目状态
    async cardJump() {
      let forwardType = this.getUrlparam('forwardType') || '';
      if (forwardType === '0') {
        let estateId = this.getUrlparam('estateId') || '';
        let roleCode = (this.getUrlparam('roleCode') === 'ZYGW' ? '置业顾问' : '销售经理') || '';
        let role = JSON.parse(localStorage.getItem('platform_role'));
        if (estateId != localStorage.getItem('platform_estateId') || roleCode != role.saleRoleName) {
          let saleUserId = this.getUrlparam('saleUserId') || '';
          let estateName = this.getUrlparam('estateName') || '';
          let user = JSON.parse(localStorage.getItem('platform_user'));
          //获取应用权限
          let res = await login.appAuthority({ estateId }, true);
          if (res.code === '0') {
            localStorage.setItem('platform_estateId', estateId);
            localStorage.setItem('platform_estateName', estateName);
            localStorage.setItem(
              'platform_role',
              JSON.stringify({
                saleRoleName: roleCode,
                saleUserId: saleUserId,
                name: user.name,
              })
            );
            let appPermit = res.data.map((item) => item.permit);
            localStorage.setItem('platform_appPermit', JSON.stringify(appPermit));
            localStorage.setItem('platform_appNews', JSON.stringify(res.data));
            window.location.reload();
          }
        }
      }
    },
  },
  created() {
    this.cardJump();
  },
};
</script>

<style lang="less" scoped>
#app {
  min-height: 100vh;
  background-color: #f5f6f7;
  font-size: 14px;
}
</style>
<style type="text/css">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
