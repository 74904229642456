<template>
  <div class="chart-line">
    <div id="myChart" :style="{ height: '100%', width: '100%' }"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'wxbChartLine',
  props: {
    isInit: { type: Boolean, default: false },
    colorArr: { type: Array },
    xAxisArr: { type: Array },
    dataList: { type: Array },
    // legendArr: { type: Array },
  },
  watch: {
    isInit(val) {
      if (val) {
        this.drawLine();
      }
    },
  },
  methods: {
    drawLine() {
      // var i=0;
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('myChart'));
      window.addEventListener('resize', function () {
        myChart.resize();
      });
      // let color = ['#19D672', '#FD517D'];
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
        },
        // legend: {
        //   data: this.legendArr
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisArr,
          axisLabel: {
            interval: 0,
            rotate: 0,
            fontSize: 12,
            padding: [0, 0, 0, 30],
            textStyle: {
              color: '#909399',
            },
            formatter: function (value) {
              return value.substring(5, value.length).replace(/-/g, '.');
            },
          },
        },
        yAxis: {
          type: 'value',
          minInterval: 1, //显示间隔的规律
        },
        series: this.dataList,
        color: this.colorArr,
      });
    },
  },
};
</script>

<style lang='less' scoped>
.chart-line {
  width: 100%;
  height: 250px;
  position: relative;
}

.title {
  position: absolute;
  left: 44%;
  top: 29%;
  font-size: rem(30);
}

.percent1 {
  position: absolute;
  left: 10%;
  top: 35%;
  font-size: rem(32);
  color: #333;
  font-weight: bold;
  line-height: rem(55);
}

.percent2 {
  position: absolute;
  right: 10%;
  top: 35%;
  font-size: rem(32);
  color: #333;
  font-weight: bold;
  line-height: rem(55);
}

.time {
  position: absolute;
  left: 24%;
  top: 70%;
}
</style>
