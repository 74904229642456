export default [
  //登录页
  {
    path: '/',
    alias: '/authorization',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/login'),
  },
  //中转页面
  {
    path: '/transit',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/transit'),
  },
  //无权限页面
  {
    path: '/nopermission',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/nopermission'),
  },

  //异常页面
  {
    path: '/abnormal',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/abnormal'),
  },
  //关联账号页面
  {
    path: '/concatAccount',
    meta: {
      title: '关联员工账号',
      wx: ['closeWindow'],
    },
    component: () => import('@/views/login/concatAccount'),
  },
];
