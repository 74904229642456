export default [
  //全部任务
  {
    path: '/taskList',
    meta: {
      title: '全部任务',
      wx: ['previewImage']
    },
    component: () => import('@/views/taskList/taskList'),
  },
];
