/*
 * @Description: 
 * @Version: 2.0
 * @Autor: yuBiao
 * @Date: 2021-08-30 10:00:45
 * @LastEditors: yuBiao
 * @LastEditTime: 2022-04-18 16:27:30
 */
const CRM='/martech-crm';

const BASIC='/martech-basic-platform';

const CLUE='/martech-clue';

const DMP='/dmp';

const AI='/martech-sale';

export {
  CRM,
  BASIC,
  CLUE,
  DMP,
  AI
};
