import axios from 'axios';
import qs from 'qs';
import { Toast } from 'vant';
import router from '../router';
import { getQueryString } from '../util/util';

Toast.allowMultiple();
axios.defaults.withCredentials = true;
const method = ['get', 'post', 'delete', 'put', 'head'];
const request = {};
for (let item of method) {
  request[item] = ({ url, data, params, hideLoading = false, showErr = true, message = '加载中...', contentType = 'application/json', removeEmpty = true, headers, replace = false, removeOneEmpty = false }) => {
    return new Promise((resolve, reject) => {
      let loading;
      if (!hideLoading) {
        loading = Toast.loading({
          duration: 0,
          message,
          forbidClick: true,
        });
      }
      url = `/bff${url}`;
      let config = {
        method: item,
        url,
        headers: {
          'Content-Type': contentType,
          ...headers,
        },
        withCredentials: true,
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
      };
      if (data) {
        if (removeEmpty) {
          removeEmptyProp(data, removeOneEmpty);
        }
        if (contentType === 'application/json') {
          config.data = data;
        } else {
          config.data = qs.stringify(data);
        }
      }
      if (params) {
        if (removeEmpty) {
          removeEmptyProp(params, removeOneEmpty);
        }
        config.params = params;
      }
      if (item === 'get') {
        if (config.params) {
          Object.assign(config.params, {
            _rnd: Math.random(),
          });
        } else {
          config.params = {
            _rnd: Math.random(),
          };
        }
      }
      axios(config)
        .then(({ data }) => {
          if (data.code !== '0') {
            try {
              switch (data.code) {
                case '-1001':
                  {
                    let opId = getQueryString('opId') || localStorage.getItem('platform_opId') || '';
                    let redirect = getQueryString('redirect');
                    let state = getQueryString('state');
                    //解决切换企业缓存丢失，消息卡片链接失效问题
                    if (redirect) {
                      localStorage.setItem('redirect', redirect);
                    }
                    console.log(opId, '卡片跳转获取opId');
                    if (opId) {
                      if (state) {
                        window.location.href = `${window.location.protocol}//` + location.host + '/#/authorization?' + `opId=${opId}` + `&state=${state}`;
                      } else {
                        window.location.href = `${window.location.protocol}//` + location.host + '/#/authorization?' + `opId=${opId}`;
                      }
                    } else {
                      Toast.fail('未获取到opId!');
                    }
                  }
                  break;
                case 'B15011':
                case 'B15001':
                case 'B16001':
                case 'B15003':
                case 'B90002':
                case 'B90008':
                case 'B60007':
                case 'B90009':
                case 'B90017':
                case 'B15006':
                case 'B90019':
                case 'B90020':
                case 'CL25008':
                  if (!replace) {
                    router.replace(`/abnormal?code=${data.code}&msg=${data.msg}`);
                  } else {
                    resolve(data);
                  }
                  break;
                default:
                  showErr && Toast.fail(data.msg);
                  break;
              }
            } catch (error) {
              console.log(error);
            }
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          if (loading) loading.clear();
        });
    });
  };
}
request['postFile'] = ({
  url,
  formData,
  hideLoading = false,
  message = '加载中...',
}) => {
  return new Promise((resolve, reject) => {
    let loading;
    if (!hideLoading) {
      loading = Toast.loading({
        duration: 0,
        message,
        forbidClick: true,
      });
    }
    url = `/bff${url}`;
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        if (data.code !== '0') {
          try {
            console.error('错误====>', data);
          } catch (error) {
            console.log(error);
          }
        } else {
          resolve(data);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {
        if (loading) loading.clear();
      });
  });
};

function removeEmptyProp(data, removeOneEmpty) {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      for (let item of data) {
        removeEmptyProp(item);
      }
    } else {
      for (let key in data) {
        if (data[key] === null || data[key] === undefined || data[key] === '') {
          if (key === 'greeting' && removeOneEmpty) {
            break;
          } else {
            delete data[key];
          }
        } else if (typeof data[key] === 'object') {
          if (Object.keys(data[key]).length === 0) {
            delete data[key];
          } else {
            removeEmptyProp(data[key], removeOneEmpty);
          }
        }
      }
    }
  }
}

export default request;
