/*
 * @Description: 
 * @Version: 2.0
 * @Autor: yuBiao
 * @Date: 2022-05-09 16:01:11
 * @LastEditors: yuBiao
 * @LastEditTime: 2022-06-02 08:43:34
 */
import request from '../../util/request';
import { CLUE } from '../apiConst';

export default {
  //获取任务列表
  getTaskList (data) {
    return request.post({ url: `${CLUE}/task/executor/v1/h5/list`, data: {...data, estateIds: localStorage.getItem('platform_estateId')} });
  },
  //确认执行任务
  performTask (params) {
    return request.put({ url: `${CLUE}/task/executor/v1/executor`, params });
  },
  //获取顾问的个人二维码
  getQrcode (params) {
    return request.get({ url: `${CLUE}/task/executor/v1/qrcode`, params });
  },

  //上报传播任务分享操作
  reportShare (params) {
    return request.get({ url: `${CLUE}/task/executor/v1/reportShare`, params, hideLoading: true});
  },
  //传播任务制作海报
  makeReport (data) {
    return request.post({ url: `${CLUE}//spread/task/v1/h5-generate-pic`, data, hideLoading: true});
  },
};
