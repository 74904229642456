<template>
  <van-picker
      show-toolbar
      :columns="columns"
      @change="onChange"
  >
    <slot/>
  </van-picker>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
// 获取一年的周次列表
const weelys = y => {
  const oneDay = moment(y + '-01-01');
  let oneWeely = null;
  if (oneDay.format('wo') == '1周') {
    oneWeely = oneDay.startOf('week').format('YYYY-MM-DD');
  } else {
    oneDay.add(1, 'weeks');
    oneWeely = oneDay.startOf('week').format('YYYY-MM-DD');
  }
  const arr = [];
  let weelyStr = '1周';
  do {
    const d = {};
    let time = moment(oneWeely);
    d.value = time.format('YYYY-MM-DD');
    d.text =
        time.format('第wo') +
        '(' +
        time.startOf('week').format('MM/DD') +
        '-' +
        time.endOf('week').format('MM/DD') +
        ')';
    arr.push(d);
    oneDay.add(1, 'weeks');
    oneWeely = oneDay.startOf('week').format('YYYY-MM-DD');
    weelyStr = oneDay.format('wo');
  } while (weelyStr != '1周' && oneWeely.indexOf(y) > -1);
  return arr;
};

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  name: 'wxbChangeWeek',
  props: {
    defaults: {
      type: [Object, String, Date],
      default: () => {
        return moment();
      }
    }
  },
  data () {
    return {
      columns: [
        {
          values: [],
          className: 'column1'
        },
        {
          values: [],
          className: 'column2'
        }
      ]
    };
  },
  created () {
    this.setData();
  },
  methods: {
    setData () {
      const defaultData = moment(this.defaults);
      let year = moment().format('YYYY');
      this.columns[0].values = [];
      for (let i = year - 21; i < year - 0 + 1; i++) {
        this.columns[0].values.push(i);
      }
      for (let i = 0; i < this.columns[0].values.length; i++) {
        if (this.columns[0].values[i] == year) {
          this.columns[0].defaultIndex = i;
          this.columns[0].valueKey = i;
          break;
        }
      }
      this.columns[1].values = weelys(year);
      for (let i = 0; i < this.columns[1].values.length; i++) {
        if (
            moment(this.columns[1].values[i].value).format('wo') ==
            defaultData.format('wo')
        ) {
          this.columns[1].defaultIndex = i;
          this.columns[1].valueKey = i;
          break;
        }
      }
    },
    onChange(picker, values) {
      picker.setColumnValues(1, weelys(values[0]));
      this.$emit('change', weelys(values[0])[picker.getIndexes()[1]]);
    },
  }
};
</script>

<style>
</style>
