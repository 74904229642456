import request from '../../util/request';
import { CLUE } from '../apiConst';

export default {
  //获取趋势数据
  getKeyUserList(data) {
    data['estateId'] = localStorage.getItem('platform_estateId');
    data['opId'] = localStorage.getItem('platform_opId');
    return request.post({ url: `${CLUE}//move/workbench/v1/key/users`, data });
  },
  //获取可能的好友
  getFriends(params) {
    params['opId'] = localStorage.getItem('platform_opId');
    return request.post({ url: `${CLUE}/clue/detail/v1/probably/friends`, data: params });
  },
  //获取潜客外部联系人
  getOtherClue(params) {
    return request.get({ url: `${CLUE}/clue/v1/clue-external-info`, params });
  },
  //获取潜客信息
  getClueInfo(params) {
    params['opId'] = localStorage.getItem('platform_opId');
    params['mobileType'] = true;
    return request.get({ url: `${CLUE}/clue/detail/v1/info`, params, replace: true });
  }

};
