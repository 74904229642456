import { render, staticRenderFns } from "./wxbUserCard.vue?vue&type=template&id=35cee86c&scoped=true&"
import script from "./wxbUserCard.vue?vue&type=script&lang=js&"
export * from "./wxbUserCard.vue?vue&type=script&lang=js&"
import style0 from "./wxbUserCard.vue?vue&type=style&index=0&id=35cee86c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35cee86c",
  null
  
)

export default component.exports