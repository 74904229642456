import request from '../../util/request';
import { BASIC } from '../apiConst';
import { CRM } from '../apiConst';

export default {
  //保存企微端Session
  setToken(token, hideLoading = false) {
    return request.post({ url: '/auth/setToken', data: { token }, hideLoading });
  },
  getCorpId(params, hideLoading = false) {
    return request.get({ url: `${BASIC}/wecom/v1/corp-id`, params, hideLoading });
  },
  getSession(hideLoading = false) {
    return request.get({ url: '/auth/session', hideLoading });
  },
  getEnter(params, hideLoading = false) {
    return request.get({ url: `${BASIC}/wecom/v1/out/js-sdk-ticket`, params, hideLoading });
  },
  //公海设置
  pubsetting(params) {
    return request.get({ url: `${CRM}/crm/pubsetting/v1/pubsetting`, params });
  },
  //添加客户权限
  salesetting(params) {
    return request.get({ url: `${CRM}/crm/salesetting/v1/salesetting`, params });
  },
  //获取token
  authLogin(params, hideLoading = false) {
    return request.get({ url: `${BASIC}/wecom/v1/authLogin`, params, hideLoading });
  },
  //获取移动端用户项目权限
  authority(params, hideLoading = false) {
    return request.get({ url: `${BASIC}/wecom/v1/authority`, params, hideLoading });
  },
  //获取移动用户在项目中应用的权限
  appAuthority(params, hideLoading = false) {
    return request.get({ url: `${BASIC}/wecom/V1/app-authority`, params: { ...params, opId: localStorage.getItem('platform_opId') }, hideLoading });
  },
  //登录接口
  homeLogin(params) {
    return request.post({ url: `${BASIC}/wecom/v1/changeBind`, data: { ...params } });
  },
};
