<template>
  <span class="wxb-tag" :style="style">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'wxbStatusTag',
  props: {
    color: {
      type: String,
      default: () => ''
    },
    borderColor: {
      type: String,
      default: () => ''
    },
    padding: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    color: {
      handler(val) {
        if (!val) return;
        this.style.color = val;
        if (val==='#4E5666') {
          this.style.backgroundColor='rgba(51, 68, 102, 0.1)';
          return;
        }
        this.style.backgroundColor = val.colorRgb();
      },
      immediate: true
    },
    borderColor: {
      handler(val) {
        if (!val) return;
        this.style.border = `1px solid ${val}`;
      },
      immediate: true
    },
    padding: {
      handler(val) {
        if (!val) return;
        this.style.padding = val;
      },
      immediate: true
    },
  },
  data() {
    return {
      style: {
        color: '#6AD038',
        backgroundColor: 'rgba(106, 208, 56, 0.1)',
      }
    };
  },
  beforeCreate() {
    String.prototype.colorRgb = function() {
      var sColor = this.toLowerCase();
      //十六进制颜色值的正则表达式
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
      // 如果是16进制颜色
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          var sColorNew = '#';
          for (var i=1; i<4; i+=1) {
            sColorNew += sColor.slice(i, i+1).concat(sColor.slice(i, i+1));
          }
          sColor = sColorNew;
        }
        //处理六位的颜色值
        var sColorChange = [];
        for (let i=1; i<7; i+=2) {
          sColorChange.push(parseInt('0x'+sColor.slice(i, i+2)));
        }
        return 'RGB(' + sColorChange.join(',') +',0.1'+ ')';
      }
      return sColor;
    };
  }
};
</script>

<style lang="less" scoped>
.wxb-tag {
  padding: 1px 4px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
}
</style>
