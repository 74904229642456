export default [
  //登录页
  {
    path: '/marketing',
    meta: {
      title: '营销',
    },
    component: () => import('@/views/marketing/marketing'),
  },
];
