export default [
  //置业顾问工作台
  {
    path: '/consultant',
    meta: {
      title: '工作台',
      wx: ['closeWindow', 'previewImage']
    },
    component: () => import('@/views/home/consultant'),
  },
  //置业顾问工作台消息提醒
  {
    path: '/consultant/remindDetail',
    meta: {
      title: '消息',
    },
    component: () => import('@/views/home/remindDetail'),
  },
  //销售经理工作台
  {
    path: '/manager',
    meta: {
      title: '工作台',
      wx: ['closeWindow']
    },
    component: () => import('@/views/home/manager'),
  },
  //任务详情
  {
    path: '/consultant/taskDetail',
    meta: {
      title: '详情',
      wx: ['hideMenuItems', 'onMenuShareAppMessage', 'onMenuShareWechat', 'onMenuShareTimeline']
    },
    component: () => import('@/views/home/taskDetail'),
  },
  // aiCard
  {
    path: '/aiCard',
    meta: {
      title: '详情',
      wx: ['hideMenuItems', 'onMenuShareAppMessage', 'onMenuShareWechat', 'onMenuShareTimeline']
    },
    component: () => import('@/views/home/aiCard'),
  },
];
