<template>
  <div class="wxb-task-card">
    <div class="task-card-title">
      <div class="title-left">
        <span>{{ info.taskType === 1 ? "传播任务" : "好友添加任务" }}</span>
      </div>
      <div class="title-right">截止时间：{{ info.taskEnd }}</div>
    </div>
    <div class="task-content" v-if="info.taskType === 1">
      <div class="task-news">
        <div class="content-wrap">
          <div :class="['content', expande ? 'expande' : ['close','van-multi-ellipsis--l2']]" ref="content" @click="copyText(info.msgContext)">
            {{ info.msgContext }}
          </div>
          <div class="expande-button-wrap" v-if="info.msgContext">
            <div class="copy-text">点击文案即可复制</div>
            <div v-if="needShowExpande">
              <div class="expande-button" @click="expandeClick" v-if="!expande">
                展开
              </div>
              <div class="expande-button" @click="expandeClick" v-else>收起</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="wxb-task-jump">
      <div @click="toAddDetail(info)" v-if="info.taskType === 2" class="wxb-task-add-friends">
        <div class="all-person">
          <div class="person-number">{{ info.allocateNum }}</div>
          <div class="number-type">分配总人数</div>
        </div>
        <div class="item-person-all">
          <div class="item-person">
            <div class="person-number">{{ info.toAddNum }}</div>
            <div class="number-type">待添加</div>
          </div>
          <div class="item-person">
            <div class="person-number">{{ info.toPassNum }}</div>
            <div class="number-type">待通过</div>
          </div>
          <div class="item-person">
            <div class="person-number">{{ info.addedNum }}</div>
            <div class="number-type">已添加</div>
          </div>
        </div>
      </div>
      <div @click="jumpUrl(info.taskId)" class="wxb-task-jump wxb-task-jump-url" v-if="type === 2">
        <img class="url-img" :src="info.cmeVo.imgUrl" alt="" />
        <div class="url-content">
          <div class="text">{{ info.cmeVo.title }}</div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icona-1212jiantou"></use>
          </svg>
        </div>
      </div>
      <div @click="previewImage(info.imageUrls)" class="wxb-task-jump wxb-task-jump-images" v-else-if="info.imageUrls && info.imageUrls.length > 0">
        <img :src="item" alt="" v-for="(item, index) of info.imageUrls.slice(0, 3)" :key="index" class="images-img" />
        <div class="more-img" v-if="info.imageUrls.length > 3">+{{ info.imageUrls.length - 3 }}</div>
      </div>
    </slot>
    <slot name="wxb-task-btn">
      <div class="wxb-task-btn">
        <div>
          <div v-if="info.taskType === 1" class="tag-list">
            <wxb-tag v-for="(item, index) of executeWay" class="tags" color="#18D0CC" :key="index">
              {{ item === "1" ? "发圈" : "群发好友" }}
            </wxb-tag>
          </div>
        </div>
        <div class="btn-group">
          <template v-if="status === 1">
            <svg v-if="showPerform" class="icon" aria-hidden="true">
              <use xlink:href="#iconrenwu_daizhihang"></use>
            </svg>
            <div v-if="showPerform" :class="info.taskType === 1 ? ['perform'] : ['perform-add']">
              待执行
            </div>
            <template v-if="info.taskType === 1">
              <img @click="imageCode(info.imageUrls)" class="code-img" v-if="type === 1" src="@/assets/images/编组 10@2x.png" alt="" />
              <img v-if="type === 2 && info.createQrCode" @click="previewImageBefore(info)" class="code-img" src="@/assets/images/编组 10@2x.png" alt="" />
              <van-button v-if="info.showButtonStatus === 1" size="small" class="btn" @click="confirm(info.taskId)">确认已完成</van-button>
            </template>
          </template>
          <template v-if="status === 2">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconrenwu_yiwancheng"></use>
            </svg>
            <div class="ok-btn">已完成</div>
            <img v-if="type === 2 && info.createQrCode" @click="previewImageBefore(info)" class="ok-code-img" src="@/assets/images/编组 10@2x.png" alt="" />
            <img @click="imageCode(info.imageUrls)" class="ok-code-img" v-if="type === 1" src="@/assets/images/编组 10@2x.png" alt="" />
          </template>
          <template v-if="status === 3">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconrenwu_yiyuqi"></use>
            </svg>
            <div class="overdue-btn">已逾期</div>
            <img v-if="type === 2 && info.createQrCode" @click="previewImageBefore(info)" class="ok-code-img" src="@/assets/images/编组 10@2x.png" alt="" />
            <img @click="imageCode(info.imageUrls)" class="ok-code-img" v-if="type === 1" src="@/assets/images/编组 10@2x.png" alt="" />
          </template>
        </div>
      </div>
    </slot>
    <slot name="wxb-task-footer">
      <div class="wxb-task-footer" v-if="info.remark">
        <span>任务备注：</span>
        {{ info.remark }}
      </div>
    </slot>

    <!--    预览弹窗 S-->
    <van-image-preview v-model="show" :images="images"> </van-image-preview>
    <!--    预览弹窗 E-->
  </div>
</template>

<script>
// import task from '@/api/task';
import taskDetail from '@/api/spreadTaskDetail';
import task from '@/api/task';
import { Toast } from 'vant';

export default {
  name: 'wxbTaskCard',
  props: {
    //任务附件类型
    type: {
      type: Number,
      default: () => 2, //1图片2网页
    },
    //是否展示待执行文案 (ps:因为首页不需要展示)
    showPerform: {
      type: Boolean,
      default: () => true,
    },
    //任务状态
    status: {
      type: Number,
      default: () => 1,
    },
    info: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      expande: true,
      needShowExpande: false,
      show: false,
      images: [],
    };
  },
  methods: {
    copyText(message) {
      //如果需要回调：
      this.$copyText(message).then(
        () => {
          Toast('复制文案成功');
        },
        function() {
          Toast('复制文案失败');
        }
      );
    },
    //批量添加好友详情
    toAddDetail(info) {
      if (!info.taskId) {
        return Toast('任务不存在!');
      }
      this.$router.push(`/addFriendsList?taskId=${info.taskId}`);
    },
    confirm(id) {
      this.$emit('confirm', id);
    },
    expandeClick() {
      this.expande = !this.expande;
    },
    //跳转任务预览地址
    jumpUrl(id) {
      // window.location.href = url;
      this.$router.push(`/consultant/taskDetail?taskId=${id}&executeWay=${this.info.executeWay}`);
    },
    //预览海报前置
    async previewImageBefore(info) {
      if (info.marTaskOssUrl) {
        this.previewImage([info.marTaskOssUrl]);
      } else {
        let { code, data } = await taskDetail.getMartaskPicture({
          taskId: info.taskId,
          estateId: localStorage.getItem('platform_estateId'),
        });
        if (code === '0') {
          this.previewImage([data.marTaskOssUrl]);
        }
      }
    },
    //预览图片
    previewImage(images) {
      this.$wx.previewImage({
        current: images[0], // 当前显示图片的http链接
        urls: images, // 需要预览的图片http链接列表
      });
    },
    //生成二维码并预览
    async imageCode(urls) {
      let loading = Toast.loading({
        duration: 0,
        message: '图片生成中...',
        forbidClick: true,
      });
      this.images = [];
      let { code, data } = await task.makeReport({backgroundImgs: urls, opId: localStorage.getItem('platform_opId')});
      if (code === '0') {
        this.images = data;
      }
      this.$nextTick(() => {
        loading.clear();
      });
      this.show = true;
    },
  },
  computed: {
    executeWay() {
      if (this.info.executeWay && this.info.executeWay.length > 0) {
        return this.info.executeWay.split(',');
      } else {
        return [];
      }
    },
  },
  mounted() {
    let lineHeight = 22;
    if (this.$refs.content) {
      if (this.$refs.content.offsetHeight > lineHeight * 2) {
        this.expande = false;
        this.needShowExpande = true;
      } else {
        this.expande = true;
        this.needShowExpande = false;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.wxb-task-card {
  margin-bottom: 12px;
  background: #ffffff;
  border-radius: 4px;

  .wxb-task-add-friends {
    padding: 29px 12px 16px 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f6f6f6;
    .person-number {
      font-size: 24px;
      font-family: SSI_Black;
      color: @gray3;
      line-height: 26px;
      margin-bottom: 4px;
    }
    .number-type {
      font-size: 12px;
      color: @gray3;
      line-height: 17px;
    }
    .all-person {
      width: 128px;
      border-right: 1px solid #f6f6f6;
      text-align: center;
    }
    .item-person-all {
      flex: 1;
      margin-left: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
    }
  }
  .task-card-title {
    padding: 14px 12px;
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left {
      font-size: 12px;
      font-weight: bold;
      color: @gary-wxb-gray-2;
      line-height: 17px;

      span {
        color: @gary-wxb-black-1;
      }
    }

    .title-right {
      font-size: 12px;
      font-weight: 400;
      color: @gary-wxb-gray-3;
      line-height: 17px;
    }
  }

  .task-content {
    margin: 16px 12px 8px 12px;

    .task-news {
      font-size: 14px;
      font-weight: 400;
      color: @gary-wxb-gray-2;

      .content {
        line-height: 20px;
        letter-spacing: 1px;
      }

      .expande {
        overflow: auto;
        height: auto;
      }

      .close {
        height: 40px;
      }

      .expande-button-wrap {
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;
        margin-bottom: 8px;
        .copy-text{
          font-size: 12px;
          color: @gray9;
        }
      }
      .expande-button {
        vertical-align: middle;
        color: #5995ef;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .wxb-task-jump {
    margin: 0px 12px;
  }

  .wxb-task-jump-url {
    display: flex;

    .url-img {
      width: 67px;
      height: 50px;
      object-fit: cover;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    .url-content {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      padding: 8px 12px 8px 8px;
      background-color: rgba(48, 49, 51, 0.04);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        width: 12px;
        height: 24px;
      }

      .text {
        width: 216px;
        height: 34px;
        font-size: 12px;
        font-weight: 400;
        color: @gary-wxb-gray-2;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2;
      }
    }
  }

  .wxb-task-jump-images {
    display: flex;
    position: relative;

    .images-img {
      width: 104px;
      height: 104px;
      border-radius: 2px;
      object-fit: cover;
      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    .more-img {
      position: absolute;
      right: 8px;
      bottom: 8px;
      width: 37px;
      height: 32px;
      background: rgba(255, 255, 255, 0.16);
      backdrop-filter: blur(8px);
      border-radius: 4px;
      text-align: center;
      line-height: 32px;
      font-size: 17px;
      font-weight: 400;
      color: #ffffff;
      text-shadow: 0px 1px 4px rgba(48, 49, 51, 0.24);
    }
  }

  .wxb-task-btn {
    margin: 24px 12px 0px 12px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-group {
      display: flex;
      align-items: center;

      .perform {
        font-size: 12px;
        color: @gray6;
        line-height: 17px;
        margin-left: 4px;
        margin-right: 16px;
      }
      .perform-add {
        font-size: 12px;
        color: @gray6;
        line-height: 17px;
        margin-left: 1px;
      }
      .ok-btn {
        font-size: 12px;
        color: @blue;
        line-height: 17px;
        margin-left: 4px;
      }

      .overdue-btn {
        font-size: 12px;
        color: #fa5d5d;
        line-height: 17px;
        margin-left: 4px;
      }

      .icon {
        width: 16px;
        height: 16px;
      }

      .code-img {
        width: 32px;
        margin-right: 8px;
      }

      .ok-code-img {
        width: 32px;
        margin-left: 16px;
      }
      .btn {
        padding: 8px 18px;
        background: #f0f4fe;
        border-radius: 4px;
        font-size: 12px !important;
        font-weight: 400;
        line-height: 17px;
        color: @blue;
      }
    }

    .tag-list {
      display: flex;

      .tags {
        &:not(:first-child) {
          margin-left: 4px;
        }
      }
    }
  }

  .wxb-task-footer {
    margin: 0px 12px;
    padding: 12px 0px;
    border-top: 1px solid #f6f6f6;
    font-size: 12px;
    font-weight: 400;
    color: @gary-wxb-gray-2;
    line-height: 17px;

    span {
      color: @gary-wxb-gray-3;
    }
  }
}
</style>
<style lang="less">
.wxb-task-card {
  .van-overlay,
  .van-image-preview__overlay {
    background: #000000 !important;
  }
}
</style>
