<template>
  <van-action-sheet class="van-tag" v-model="show" @click-overlay="cancel" @open="open">
    <div class="wxb-pop-btn">
      <div class="pop-cancel-btn" @click="cancel">取消</div>
      <div class="pop-title">标签</div>
      <div class="pop-confirm-btn" @click="confirm">确认</div>
    </div>
    <div class="content tags" v-if="tagList.length > 0">
      <div class="tags-item" v-for="item of tagList" :key="item.tagId">
        <div class="tags-title">{{ item.tagName }}</div>
        <div class="tag-list">
          <span v-for="tagItem of item.childrenTags" :key="tagItem.tagId"
                class="tag-item"
                :class="findTage(tagItem) && 'tag-item-active'"
                @click="select(tagItem)">{{ tagItem.tagName }}</span>
        </div>
      </div>
    </div>
    <div class="no-tag"  v-else>
      <wxbNoData></wxbNoData>
    </div>
  </van-action-sheet>
</template>

<script>

import wxbNoData from './wxbNoData';

export default {
  name: 'actionSheetTag',
  components: {
    wxbNoData
  },
  props: {
    actionTagShow: {
      type: Boolean,
      default: false
    },
    tagList: {
      type: Array,
      default: () => []
    },
    selectTagList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    actionTagShow(newVal) {
      this.show = newVal;
    },
    selectTagList(val) {
      this.selectTags = val.slice();
    }
  },
  data() {
    return {
      show: false,
      selectTags: [],
    };
  },
  methods: {
    findTage(tagItem) {
      let val = this.selectTags.some(item => item.tagId === tagItem.tagId);
      return val;
    },
    select(val) {
      let isHash = this.selectTags.findIndex(item => val.tagId === item.tagId);
      if (isHash > -1) {
        this.selectTags.splice(isHash, 1);
      }
      else {
        val.labelId=val.tagId;
        val.name=val.tagName;
        this.selectTags.push(val);
      }
    },
    cancel() {
      this.selectTags = [];
      this.$emit('actionTagClose', {type: false, data: null});
    },
    flatten(arr) {
      //初始化扁平化后的数组
      let res=[];
      //遍历需要扁平化的数组
      for (let i=0, len=arr.length;i<len;i++) {
        if (Array.isArray(arr[i].childrenTags)) {
          res=res.concat(this.flatten(arr[i].childrenTags));
        }
        else {
          res.push(arr[i]);
        }
      }
      return res;
    },

    confirm() {
      let filterList=this.flatten(this.tagList);
      let arr=[];
      for (let i of this.selectTags) {
        for (let j of filterList) {
          if (i.tagId===j.tagId) {
            arr.push(i);
          }
        }
      }
      console.log(this.selectTags, '筛选前');
      console.log(arr, '筛选后');
      this.$emit('actionTagClose', {type: false, data: arr});
    },
    open() {
      this.selectTags = this.selectTagList.slice();
    }
  },
};
</script>

<style lang="less" scoped>
.van-tag{
  height: 300px;
  width: 100%;
  .van-action-sheet__content{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.content {
  overflow-y: auto;
  height: calc(100% - 44px);
  margin-bottom: 60px;
}
.no-tag{
  margin-bottom: 84px;
}
.tags {
  box-sizing: border-box;
  padding: 16px;
  font-weight: 400;
  font-size: 12px;

  .tags-title {
    color: @gary-wxb-black-1;
  }
  .tags-item {
    margin-bottom: 32px;
  }
  .tags-item:last-child{
    margin-bottom: 0;
  }
  .tag-list {
    display: flex;
    flex-wrap: wrap;

    .tag-item {
      color: @gary-wxb-gray-2;
      white-space: nowrap;
      padding: 0 8px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #EBEEF5;
      text-align: center;
      margin: 8px 8px 0 0;
    }

    .tag-item-active {
      border-radius: 4px;
      border: 1px solid #3A74F2;
      color: @blue;
    }
  }
}
</style>
