const state = {
  followData: sessionStorage.getItem('crmFollowData'),
  dateRange: [],
  followOrOverdue: sessionStorage.getItem('crmFollowOrOverdue'),
  overdueData: sessionStorage.getItem('crmOverdueData')
};

const mutations = {
  setDateRange: (state, data) => {
    console.log('setDateRange', data);
    state.dateRange = data;
  },
  setFollowOverdueType: (state, data) => {
    state.followOrOverdue = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
