/*
 * @Author: your name
 * @Date: 2021-11-03 10:31:06
 * @LastEditTime: 2021-11-05 17:59:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ftd-platform-h5\src\router\spreadTaskDetail\indexs.js
 */

export default [
  //传播任务详情
  {
    path: '/spreadTaskDetail',
    meta: {
      title: '传播任务详情',
      wx: ['previewImage'],
    },
    component: () => import('@/views/spreadTaskDetail/spreadTaskDetail'),
  },
];
