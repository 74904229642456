<template>
  <div class="wxbTag" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'wxbTag',
  props: {
    color: {
      type: String,
      default: ()=>''
    },
  },
  data() {
    return {
      style: {
        color: '',
        backgroundColor: '',
      }
    };
  },
  watch: {
    color: {
      handler(val) {
        if (!val) {
          new Error('前传入颜色值');
          return;
        }
        this.style.color = val;
        this.style.borderColor = val.colorRgb();
      },
      immediate: true
    },
  },
  beforeCreate() {
    String.prototype.colorRgb = function() {
      var sColor = this.toLowerCase();
      //十六进制颜色值的正则表达式
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
      // 如果是16进制颜色
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          var sColorNew = '#';
          for (var i=1; i<4; i+=1) {
            sColorNew += sColor.slice(i, i+1).concat(sColor.slice(i, i+1));
          }
          sColor = sColorNew;
        }
        //处理六位的颜色值
        var sColorChange = [];
        for (let i=1; i<7; i+=2) {
          sColorChange.push(parseInt('0x'+sColor.slice(i, i+2)));
        }
        return 'RGB(' + sColorChange.join(',') +',0.4'+ ')';
      }
      return sColor;
    };
  }
};
</script>

<style lang="less" scoped>
.wxbTag{
  padding: 1px 4px;
  border-radius: 2px;
  border: 1px solid;
  line-height: 14px;
  text-align: center;
  font-size: 10px;
}
</style>
