<template>
  <div class="no-data">
    <div class="no-data-img"></div>
    <div>{{desc}}</div>
  </div>
</template>
<script>
export default {
  name: 'wxbNoData',
  props: {
    desc: {
      type: String,
      default: '暂无数据',
    },
  },
};
</script>

<style lang="less" scoped>
.no-data {
  padding: 42px 0;
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  text-align: center;
  &-img {
    width: 240px;
    height: 148px;
    background: url('../../assets/images/noStore.png');
    background-size: cover;
    margin: 0 auto;
  }
}
</style>
