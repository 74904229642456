export default [
  //我的
  {
    path: '/mine',
    meta: {
      title: '我的',
      wx: ['closeWindow'],
    },
    component: () => import('@/views/mine/mine'),
  },
  //我的名片
  {
    path: '/myCard',
    meta: {
      title: '我的名片',
    },
    component: () => import('@/views/mine/myCard'),
  },
  //管理名片信息
  {
    path: '/setCard',
    meta: {
      title: '管理名片信息',
      wx: ['chooseImage', 'getLocalImgData', 'previewImage']
    },
    component: () => import('@/views/mine/setCard'),
  },
  //设置
  {
    path: '/setting',
    meta: {
      title: '设置'
    },
    component: () => import('@/views/mine/setting'),
  },
  //账号绑定
  {
    path: '/accountBind',
    meta: {
      title: '账号绑定'
    },
    component: () => import('@/views/mine/accountBind'),
  },
  //修改密码
  {
    path: '/modifyPassword',
    meta: {
      title: '修改密码'
    },
    component: () => import('@/views/mine/modifyPassword'),
  },
  //雷达设置
  {
    path: '/mine/radar-set',
    meta: {
      title: '雷达设置'
    },
    component: () => import('@/views/mine/radarSetting'),
  },
  // 拓客码
  {
    path: '/mine/talkCode',
    meta: {
      title: '我的拓客码',
      wx: ['previewImage'],
    },
    component: () => import('@/views/mine/talkCode'),
  },
];
