const managerTabList = [
  {
    to: '/fieldManage',
    icon: { active: '#icontab_anchangguanlixuanzhong', inactive: '#icontab_anchangguanliweixuanzhong' },
    name: '案场管理'
  },
  {
    to: '/clientManage',
    icon: { active: '#iconkehu_yixuanzhong', inactive: '#iconkehu_weixuanzhong' },
    name: '客户管理'
  },
  {
    to: '/mine',
    icon: { active: '#iconwode_yixuanzhong', inactive: '#iconwode_weixuanzhong' },
    name: '我的'
  }
];

const saleTabList = [
  {
    to: '/home',
    icon: { active: '#icondaiban_yixuanzhong', inactive: '#icondaiban_weixuanzhong' },
    name: '待办'
  },
  {
    to: '/client',
    icon: { active: '#iconkehu_yixuanzhong', inactive: '#iconkehu_weixuanzhong' },
    name: '客户'
  },
  {
    to: '/mine',
    icon: { active: '#iconwode_yixuanzhong', inactive: '#iconwode_weixuanzhong' },
    name: '我的'
  }
];
const state = {
  tabBarList: localStorage.getItem('crmClientManage') ? managerTabList : saleTabList,
  consultant: localStorage.getItem('crmClientManage') ? false : true //是否是置业顾问
};

const mutations = {
  SET_BAR: (state, data) => {
    state.tabBarList = data;
    state.consultant = data.some(item => item.to === '/home');
  }
};

const actions = {
  setConsultant({ commit }) {
    commit('SET_BAR', saleTabList);
  }, //切换成客户跟进
  setManager({ commit }) {
    commit('SET_BAR', managerTabList);
  }, //切换成案场管理

};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
