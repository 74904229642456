/*
 * @Author: your name
 * @Date: 2021-11-03 10:59:11
 * @LastEditTime: 2021-11-04 11:25:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ftd-platform-h5\src\api\spreadTaskDetail\index.js
 */
import request from '../../util/request';
import { CLUE } from '../apiConst';

export default {
    //传播任务详情
    getSpreadDetail(params, hideLoading = false) {
        return request.get({ url: `${CLUE}/spread/task/v1/detail`, params, hideLoading });
    },
    //生成海报
    getMartaskPicture(params, hideLoading = false) {
        return request.get({ url: `${CLUE}/spread/task/v1/generate-martask-picture`, params, hideLoading });
    },
};
