import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex';
import Vant from 'vant';
import 'wxb-nrmh5-npm/lib/wxb-nrmh5-npm.css'; //引入样式文件
import 'vant/lib/index.less';
import './fonts/font.css'; //引入字体包
//全局组件注入
import './components/index';
//移动端调试vconsole注入
import './util/vConsole';
import wxb from 'wxb-nrmh5-npm';
import login from '@/api/login';
import VueClipboard from 'vue-clipboard2';

//接入skywalking错误上报
//1，关于collector的地址，示例是根据约定的测试环境访问域名有出现.test来判断的。还有一个方案，是执行CI的时候，带上环境变量来判断（这个需要修改CI）
//2，集成后ajax、frames、js、promise、resource、vue等错误信息也能在skywalking管理平台上查看到
import ClientMonitor from 'wxb-skywalking-client-js';
const domain = location.hostname;
const oap = {
  test: 'https://sky.test.wangxiaobao.com',
  prod: 'https://sky.wangxiaobao.com'
};
let env = 'prod';
if (domain.indexOf('.test') !== -1) {
  env = 'test';
} else if (/^(localhost|(\d{1,3}\.){3}\d{1,3})$/i.test(domain)) {
  env = 'local';
}

if (oap[env]) {
  ClientMonitor.register({
    collector: oap[env],
    service: 'ftd-platform-h5', //前端静态项目的名称
    pagePath: 'index.html',
    serviceVersion: 'v1.0.1', //版本号（如果是要区分哪次前端页面版本，可以修改此参数，在错误日志管理里面该参数会有体现）
    vue: Vue,
    useFmp: true,
    traceTimeInterval: 10000, //上报频率（毫秒），无接口请求的时候是不会进行上报动作的，可根据自己项目接口请求情况自行调整频率
    enableSPA: true
  });
}
 
Vue.use(VueClipboard);
Vue.use(wxb);

Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$wx = window.wx; //挂载wxJSsdk

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  next();
});
router.afterEach(async (to) => {
  let corpId = localStorage.getItem('platform_corpId');
  let suiteId = localStorage.getItem('platform_agentId');
  let opId = localStorage.getItem('platform_opId');
  if (to.meta.wx && to.meta.wx.length > 0 && suiteId && corpId) {
    const targetUrl = location.href.split('#')[0];
    let params = {
      url: targetUrl,
      suiteId,
      corpId,
      opId
    };
    let res = await login.getEnter(params);
    if (res.code === '0') {
      window.wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        appId: corpId, // 必填，企业微信的corpID
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.configSignature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: to.meta.wx, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });
    }
  }
});

window.wx.error((res) => {
  console.log(res, 'wxJSSDK调用报错');
  //调用skywalking主动上报wx报错
  ClientMonitor.reportFrameErrors({
    collector: oap[env],
    service: 'ftd-platform-h5', //前端静态项目的名称
    pagePath: 'index.html',
    serviceVersion: 'v1.0.0', //版本号（如果是要区分哪次前端页面版本，可以修改此参数，在错误日志管理里面该参数会有体现）
  }, new Error(res.errMsg));
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
